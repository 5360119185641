var render = function render(){var _vm=this,_c=_vm._self._c;return _c('datatable',{attrs:{"scroll-x":false,"columns":[
    { id: 'date', name: 'Tanggal' },
    { id: 'description', name: 'Deskripsi' },
    { id: 'debt', name: 'Debit', theadClass: 'text-right' },
    { id: 'credit', name: 'Kredit', theadClass: 'text-right' },
    { id: 'balance', name: 'Saldo', theadClass: 'text-right' }
  ],"total":_vm.balanceTrxes.meta.page.total,"perPage":_vm.balanceTrxes.meta.page.perPage,"currentPage":_vm.balanceTrxes.meta.page.currentPage},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.balanceTrxes.data),function(balanceTrx){return _c('tr',{key:balanceTrx.id,class:[classes.tr, 'bg-white']},[_c('td',{class:classes.td},[_vm._v(_vm._s(_vm._f("formatDate")(balanceTrx.attributes.createdAt)))]),_c('td',{class:classes.td},[_vm._v(_vm._s(balanceTrx.attributes.description ?? '-'))]),_c('td',{class:[classes.td, 'text-right']},[(balanceTrx.attributes.order_balance_amount < 0)?_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(Math.abs(balanceTrx.attributes.order_balance_amount))))]):_c('span',[_vm._v("0")])]),_c('td',{class:[classes.td, 'text-right']},[(balanceTrx.attributes.order_balance_amount > 0)?_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(Math.abs(balanceTrx.attributes.order_balance_amount))))]):_c('span',[_vm._v("0")])]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(_vm._s(_vm._f("toCurrency")(balanceTrx.attributes.order_balance)))])])})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }