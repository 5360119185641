<template>
  <datatable
    :scroll-x="false"
    :columns="[
      { id: 'date', name: 'Tanggal' },
      { id: 'description', name: 'Deskripsi' },
      { id: 'debt', name: 'Debit', theadClass: 'text-right' },
      { id: 'credit', name: 'Kredit', theadClass: 'text-right' },
      { id: 'balance', name: 'Saldo', theadClass: 'text-right' },
    ]"
    :total="balanceTrxes.meta.page.total"
    :perPage="balanceTrxes.meta.page.perPage"
    :currentPage="balanceTrxes.meta.page.currentPage"
    @pagechanged="onPageChange"
  >
    <template #tbody="{ classes }">
      <tr
        v-for="balanceTrx in balanceTrxes.data"
        :key="balanceTrx.id"
        :class="[classes.tr, 'bg-white']"
      >
        <td :class="classes.td">
          {{ balanceTrx.attributes.createdAt | formatDate }}
        </td>
        <td :class="classes.td">
          {{ balanceTrx.attributes.description ?? '-' }}
        </td>
        <td :class="[classes.td, 'text-right']">
          <span v-if="balanceTrx.attributes.amount < 0">{{
            Math.abs(balanceTrx.attributes.amount) | toCurrency
          }}</span>
          <span v-else>0</span>
        </td>
        <td :class="[classes.td, 'text-right']">
          <span v-if="balanceTrx.attributes.amount > 0">{{
            Math.abs(balanceTrx.attributes.amount) | toCurrency
          }}</span>
          <span v-else>0</span>
        </td>
        <td :class="[classes.td, 'text-right']">
          {{ balanceTrx.attributes.balance | toCurrency }}
        </td>
      </tr>
    </template>
  </datatable>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  props: {
    officeId: String,
  },
  emits: ['update:loading'],
  data() {
    return {
      balanceTrxes: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  methods: {
    async loadBalanceTrxes(params) {
      this.$emit('update:loading', true);

      const [res, err] = await this.request(
        `/api/v1/offices/${this.officeId}/stockist-loan-trxes`,
        {
          params: {
            'page[size]': 10,
            'fields[stockist-loan-trxes]':
              'createdAt,description,amount,balance',
            ...params,
          },
        }
      );

      if (!err) {
        this.balanceTrxes = res;
      }

      this.$emit('update:loading', false);
    },
    onPageChange(page) {
      this.loadBalanceTrxes({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadBalanceTrxes();
  },
};
</script>
